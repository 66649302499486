/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

    html,
    body {
        font-size: 12px;
    }

    .OpCoSpecificLogo {
        max-width: 290px;
    }
}

@media only screen and (min-width: 1366px) {

    html,
    body {
        font-size: 12px;
    }

    .OpCoSpecificLogo {
        max-width: 290px;
    }
}

@media only screen and (min-width: 1400px) {

    html,
    body {
        font-size: 14px;
    }

    .OpCoSpecificLogo {
        max-width: 290px;
    }
}

@media only screen and (min-width: 1900px) {

    html,
    body {
        font-size: 16px;
    }

    .OpCoSpecificLogo {
        max-width: 300px;
    }
}